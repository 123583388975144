<template>
    <div class="user-center-index">
      <el-row>
          <el-card class="box-card user-card"  shadow="never">
              <el-row>
                  <el-col :span="2">
                      <el-image :src="$imgurl + user.avatar" >
                          <div slot="error" class="image-slot">
<!--                              <i class="iconfont icon-biaoqiankuozhan_wode-135"></i>-->
                              <img src="../../../assets/images/userimg.png" width="70"/>
                          </div>
                      </el-image>
                  </el-col>
                  <el-col :span="17" class="info">
                      <p>{{user.nickname || user.username || user.mobile}}</p>
                      <p>
                        <span  @click="go('UserBalance')">
                            <span class="a-title">余额：</span>
                            {{user.money || 0.00}}
                        </span>
                          <span style="margin: 0 3px">|</span>
                        <span @click="go('userOrder')">
                            <span class="a-title" >订单数：</span>{{user.order || 0}}
                        </span>
<!--                          <el-button @click="goRecharge" size="mini" type="danger">充值</el-button>-->
                      </p>
                  </el-col>
              </el-row>

<!--              <div v-for="o in 4" :key="o" class="text item">-->
<!--                  {{'列表内容 ' + o }}-->
<!--              </div>-->
          </el-card>
      </el-row>
        <el-row :gutter="10">
            <el-col :span="14" >
                <el-image class="img-card" :src="adImg" @click="go('laboratory')"/>
            </el-col>
            <el-col :span="10">
                <el-card class="box-card " style="height: 233px;" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>最新消息</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="go('userMessage')">全部</el-button>
                    </div>
                    <div v-show="dataList.messageList.length > 0" :title="item.title"  class="item" v-for="(item, index) in dataList.messageList" :key="index" @click="detail(item)">
                        <div class="title">{{item.messagenotice.message_title}}</div>
                        <div class="time">{{$formatDate(item.createtime)}}</div>
                    </div>
                    <el-empty description="暂无数据" :image-size="100" v-show=" dataList.messageList.length === 0"></el-empty>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="10">
            <el-col :span="12">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>我的下载</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="go('userDocument')">全部</el-button>
                    </div>
                    <div v-show="dataList.downList.length > 0" :title="item.title"  class="item" v-for="(item, index) in dataList.downList" :key="index" @click="go('detail', item.archives_id)">
                        <div class="title">{{item.title}}</div>
                        <div class="time">{{$formatDate(item.updatetime)}}</div>
                    </div>
                    <el-empty description="暂无数据" :image-size="100" v-show=" dataList.downList.length === 0"></el-empty>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card class="box-card " shadow="never">
                    <div slot="header" class="clearfix">
                        <span>我的收藏</span>
                        <el-button style="float: right; padding: 3px 0" type="text" @click="go('userCollect')">全部</el-button>
                    </div>
                    <div v-show="dataList.collList.length > 0" :title="item.title" class="item" v-for="(item, index) in dataList.collList" :key="index" @click="go(item.type === 'channel' ? 'detailLaboratory' : 'detail', item.aid)">
                        <div class="title">{{item.title}}</div>
                        <div class="time">{{$formatDate(item.updatetime)}}</div>
                    </div>
                    <el-empty description="暂无数据" :image-size="100" v-show=" dataList.collList.length === 0"></el-empty>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
                title="消息详情"
                :visible.sync="dialogFormVisible"
                v-if="dialogFormVisible"
                width="450px"
                custom-class="msg-dialog"
                :modal="false"
        >
            <el-form>
                <el-form-item label="标题：">
                    <span>{{msgDetail.messagenotice.message_title}}</span>
                </el-form-item>
                <el-form-item label="时间：">
                    <span>{{$formatDate(msgDetail.createtime, true)}}</span>
                </el-form-item>
                <el-form-item label="内容：">
                    <div v-html="msgDetail.messagenotice.message_content">
                    </div>
                </el-form-item>
            </el-form>



            <!--            <div slot="footer" class="dialog-footer" >-->
            <!--                <el-button @click="dialogFormVisible = false">确定</el-button>-->
            <!--            </div>-->
        </el-dialog>
    </div>
</template>

<script>
    import { UserService } from "../../../api/user";
    import { mapGetters } from 'vuex'
    export default {
        name: "user-center-index",
        data() {
            return {
                msgDetail: {},
                dialogFormVisible: false,
                dataList: {
                    collList: [],
                    downList: [],
                    messageList: [],
                },
                adImg: require('../../../assets/images/_b4e0106d4953de0782b2742b49dce2c1_63564.jpg'),
                recordList: [],
                circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            }
        },
        computed: {
            ...mapGetters(['user']),
        },
        methods: {
            detail(item) {
                // this.$dialog
                UserService.messagedetail({id: item.rec_id}).then(res => {
                    // // console.log('res', res)
                    this.msgDetail = res.data
                    this.dialogFormVisible = true
                })
            },
            go(name, id) {
                this.$router.push({
                    name,
                    query: {
                        id
                    }
                })
            },
            getList() {
                UserService.center().then(res => {
                    // // console.log('res222', res)
                        this.dataList = res.data
                })
            }
        },
        mounted() {
            this.getList()
        }
    }
</script>
<style lang="scss">
    .msg-dialog{

        .el-dialog__body{
            height: 600px;
            overflow: auto;
        }
    }
</style>
<style scoped lang="scss">
    .user-center-index{
        .img-card{
            margin: 0 0 13px 0;
            height: 235px;
            border-radius: 18px;
            width: 100%;
        }
        .box-card{
            margin-bottom: 20px;
            height: 348px;
            .el-empty{
                padding: 0 !important;
            }
            .item{
                display: flex;
                line-height: 1.8;
                cursor: pointer;
                .title{
                    flex: 1;
                    color: $font_2;
                    display: block;
                    overflow: hidden;
                    word-break: keep-all;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .time{
                    color: $font_3;
                    flex: 0.3;
                    text-align: right;
                    margin: 3px 0;
                }
            }
        }
        .user-card{
            height: 120px;
            .el-image{
                width: 70px;
                height: 70px;
                align-items: center;
                vertical-align: middle;
                text-align: center;
                line-height: 70px;
                border-radius: 50px;
                border: 1px solid $brand_color;
                i{
                    font-size: 60px;
                }
            }
            .info{
                cursor: pointer;
                p{
                    margin: 5px 0 25px 0;
                    font-size: 14px;
                    .a-title{
                        font-weight: 400;
                        font-size: 14px;
                        color: #778CA2;
                    }
                }
            }
        }
        .box-card:nth-child(1)
    }
</style>